import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import styled from "styled-components";
import Logo from "../components/icons/logo";

import "../components/layout.js"

const HeaderWrapper = styled.div`
	position: fixed; 
	top: 0;
	left: 0;
	width: 100%;
	padding: 16px;
	text-align: center;
	text-decoration: underline;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	height: 100vh;

	svg {
		max-height: 30vh;
		margin: 30px 0;
	}
`

const Heading = styled.h1`
	text-align: center;
`


const IndexPage = () => (
	<Wrapper>
		<HeaderWrapper>
		<Link
			to="/"
			style={{
				fontSize: `var(--font-sm)`,
				textDecoration: `none`,
			}}
		>
			Take me home, country roads...
		</Link>
	</HeaderWrapper>
		<Seo title="Home" />
			<Heading>
				Welkom bij de Escalatie Federatie
			</Heading>
			<Logo/>
			<Link to="/bier-halen">Wie moet er bier halen?</Link>
	</Wrapper>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
