import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components";

const HeaderWrapper = styled.div`
	position: fixed; 
	top: 0;
	left: 0;
	width: 100%;
	padding: 16px;
	text-align: center;
	text-decoration: underline;
`


const Header = () => (
	<HeaderWrapper>
		<Link
			to="/"
			style={{
				fontSize: `var(--font-sm)`,
				textDecoration: `none`,
			}}
		>
			Take me home, country roads...
		</Link>
	</HeaderWrapper>

	// <header
	//   style={{
	//     margin: `0 auto`,
	//     padding: `var(--space-4) var(--size-gutter)`,
	//     display: `flex`,
	//     alignItems: `center`,
	//     justifyContent: `space-between`,
	//   }}
	// >
	//   <Link
	//     to="/"
	//     style={{
	//       fontSize: `var(--font-sm)`,
	//       textDecoration: `none`,
	//     }}
	//   >
	//     {siteTitle}
	//   </Link>
	// </header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: `Escalatie Federatie`,
}

export default Header
